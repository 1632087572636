<template>
<div id="projects">
	<vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
    <section id="inner-headline">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="pageTitle">Career - Jobs</h2>
                </div>
            </div>
        </div>
    </section>
    <section id="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <table class="table table-striped table-condensed">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Job Title</th>
                                <th>Location</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in rows" :key="row.id">
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{ row.title }}
                                </td>
                                <td>
                                   {{ row.location }}
                                </td>
                                <td>
									<router-link class="btn btn-sm" :to="{name: 'job', params: {id: row.id}}">
										<i class="fa fa-check"></i> Appy Now
									</router-link>
                                </td>
                            </tr>
                            <tr v-if="!rows.length">
                                <td colspan="4" align="center">
                                    No Records Found.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script type="text/javascript" scoped>
export default{
	name: "JobsView",
	data(){
		return {
			rows: [],
		}
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_jobs",
		}).then((res) => {
			this.rows = res.data;
			this.$refs.topProgress.done();
		});
	}
}
</script>

<style type="text/css" scoped>
</style>